import React from 'react';
import { useCurrentCity } from '@cp/entities/location';
import { ClientWebPageCommonTemplateParametersDictionary } from '@common/model/meta/clientWebPageTemplateParameters';
import { ClientWebPage } from '@common/model/meta/clientWebPage';

export const useCommonTemplateParameters = <P extends ClientWebPage>() => {
  const { currentCity } = useCurrentCity({ allowEmptyValue: true });

  return React.useMemo<ClientWebPageCommonTemplateParametersDictionary[P]>(
    () =>
      currentCity
        ? {
            cityName: currentCity.translation.name,
            cityNamePrepositional: currentCity.translation.namePrepositional,
          }
        : {},
    [currentCity],
  );
};

import React from 'react';
import { styled } from '@cp/ui/theme';
import { Box, BoxProps } from '@cp/ui/components';
import { useIsMobile } from '@cp/ds/hooks/useIsMobile';
import { ReviewCard, ReviewContent, ReviewPrice } from '#entities/review';
import { SwiperSlider } from '#shared/ui/swiper';

const data = [
  {
    subject: '1 ребенок, 5 лет',
    description: 'Забрать ребенка из детского сада, дома покормить, поиграть. В 21:00 уложить спать. Родители вернутся в 22:30.',
    price: 1500,
  },
  {
    subject: '1 ребенок, 8 лет',
    description: 'Встретить ребенка в школе, дома покормить, погулять и сделать уроки. Приготовить ужин для семьи к 20:00.',
    price: 1200,
  },
  {
    subject: '2 ребенка, 3 и 6 лет',
    description: 'Погулять с детьми около дома, поиграть в развивающие игры дома, накормить обедом и уложить спать.',
    price: 2000,
  },
];

const Root = styled(Box, { name: 'TextReviewsSlider' })(() => ({}));

export interface TextReviewsSliderProps extends BoxProps {}
export const TextReviewsSlider: React.FC<TextReviewsSliderProps> = ({ ...props }) => {
  const isMobile = useIsMobile();
  return (
    <Root {...props}>
      <SwiperSlider config={{ slidesPerView: isMobile ? 1 : 3, navigation: true, spaceBetween: 20 }}>
        {data.map((review, i) => (
          <ReviewCard footer={<ReviewPrice value={review.price} />} header="Заказ выполнен" key={i} rating={5}>
            <ReviewContent description={review.description} subject={review.subject} />
          </ReviewCard>
        ))}
      </SwiperSlider>
    </Root>
  );
};

import React from 'react';
import { Box, Image } from '@cp/ui/components';
import { Button } from '@cp/ds/components/button';
import { MOBILE_BREAKPOINT_THRESHOLD, styled, useTheme } from '@cp/ui/theme';
import { Typography } from '@cp/ds/components/typography';
import { RoutedLink } from 'src/shared/ui/link';
import { Logo } from '#shared/ui/logo';
import stubAvatars from './assets/stub-avatars.png';

const Root = styled(Box, { name: 'AdvantageBody' })(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.brand.main}`,
  padding: '20px 12px',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  textAlign: 'initial',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    gap: '40px',
    flexDirection: 'row',
  },
}));

export const AdvantageBody = () => {
  const theme = useTheme();
  return (
    <Root>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
        <Image alt="" height={stubAvatars.height} src={stubAvatars.src} width={stubAvatars.width} />
        <RoutedLink href="/nannies">
          <Button as="span" size="small">
            Найти няню
          </Button>
        </RoutedLink>
      </Box>
      <Box>
        <Typography variant="h2">
          👍🏻&nbsp;97%&nbsp;<Typography variant="button">Положительных отзывов</Typography>
        </Typography>
        <Typography component="p" sx={{ color: theme.palette.text.secondary, m: '8px 0' }}>
          1000 отзывов оставили родители за последний год. Из них 970 положительные.
        </Typography>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Logo />
          <Typography variant="button">— ваш надежный помощник</Typography>
        </Box>
        <Typography component="p" sx={{ color: theme.palette.text.secondary, m: '8px 0' }}>
          Няня поиграет с ребенком, погуляет, встретит со школы, поможет с домашними заданиями, чтобы вы спокойно позанимались своими
          делами.
        </Typography>
      </Box>
    </Root>
  );
};

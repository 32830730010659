import React, { ComponentType, PropsWithChildren } from 'react';
import { MetaTagConfig } from './metaTagConfig';

export interface PageMetaTagsProps {
  tagsConfig?: MetaTagConfig[];
  isClosedForRobots?: boolean;
  /** Component to render Head like react-helmet */
  HeadComponent: ComponentType<PropsWithChildren>;
}
export const PageMetaTags = ({ HeadComponent, tagsConfig, isClosedForRobots }: PageMetaTagsProps) => (
  <HeadComponent>
    {tagsConfig?.map(({ tagName, tagContent }) => {
      if (tagName === 'title') {
        return <title key={tagName}>{tagContent}</title>;
      }

      return <meta content={tagContent} key={tagName} name={tagName} />;
    })}
    {isClosedForRobots ? <meta content="noindex, nofollow" name="robots" /> : null};
  </HeadComponent>
);

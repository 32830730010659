import { useTheme } from '@cp/ds/hooks/useTheme';
import React from 'react';
import { getEmploymentAgencySchema } from '@youhelp/client.web/app/shared/schemas';
import { usePageMetaTags } from '@youhelp/client.web/app/shared/hooks/useMetaTags';
import { PageMetaTags } from '@cp/shared/metaTags';
import { Schema } from '@cp/ds/components/schema';
import { TypographyAccent } from '@cp/ui/components';
import { HeaderFeature } from 'src/widgets/header';
import { useHelmet } from 'src/shared/helmet';
import { Header, Intro } from '#widgets/header';
import { SafetyWidget } from '#widgets/safety';
import { Section, SectionHeader } from '#shared/ui/section';
import { AdvantageWidget } from '#widgets/advantage';
import { TextReviewsSlider } from '#widgets/textReviewsSlider';
import { FastActions } from '#widgets/header/ui/fastActions';
import { HowToUse } from '#widgets/howToUse';
import { VideoReviews } from '#widgets/videoReviews';
import { Footer } from '#widgets/footer';

export const Home = () => {
  const Helmet = useHelmet();
  const theme = useTheme();
  const employmentAgencySchema = React.useMemo(() => getEmploymentAgencySchema(), []);
  const metaTagsRenderConfig = usePageMetaTags();
  return (
    <>
      <main>
        <PageMetaTags HeadComponent={Helmet} tagsConfig={metaTagsRenderConfig} />
        <Section sx={{ backgroundColor: theme.palette['gray ultra light'], pt: 0 }}>
          <Header mobileFeatures={{ [HeaderFeature.LoginButton]: true, [HeaderFeature.BurgerMenu]: false }} />
          <Intro />
        </Section>
        <Section sx={{ p: '56px 0' }}>
          <SafetyWidget />
        </Section>
        <Section sx={{ pb: '56px' }}>
          <SectionHeader>300+ НЯНЬ В МОСКВЕ</SectionHeader>
          <AdvantageWidget />
        </Section>
        <Section sx={{ background: theme.palette['gray ultra light'] }}>
          <SectionHeader>
            <TypographyAccent>3 422</TypographyAccent> ВЫПОЛНЕННЫХ ЗАКАЗОВ С 2019 ГОДА
          </SectionHeader>
          <TextReviewsSlider sx={{ pb: '60px' }} />
          <FastActions />
        </Section>
        <Section>
          <SectionHeader>РОДИТЕЛИ О НАШИХ НЯНЯХ</SectionHeader>
          <VideoReviews />
        </Section>
        <Section>
          <SectionHeader>
            <TypographyAccent>КАК ЛЕГКО</TypographyAccent> НАЙТИ НЯНЮ, КОТОРОЙ МОЖНО <TypographyAccent>ДОВЕРЯТЬ</TypographyAccent>
          </SectionHeader>
          <HowToUse />
        </Section>
        <Schema schema={employmentAgencySchema} />
      </main>
      <Footer />
    </>
  );
};

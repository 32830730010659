import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD, styled } from '@cp/ui/theme';
import { Box, Image, TypographyAccent } from '@cp/ui/components';
import { Typography } from '@cp/ds/components/typography';
import { Button } from '@cp/ds/components/button';
import { ArrowRightIcon } from '@cp/ds/icons';
import safety from './assets/safety.svg';
import { Steps } from './steps';

const Root = styled(Box, { name: 'SafetyWidget', slot: 'root' })(({ theme }) => ({
  '--color--text--accent': theme.palette.text.accent,
  display: 'grid',
  gap: '20px',
  gridTemplate: '"HeadText" "Texts" "Steps" "Checks"',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    gridTemplate: '"HeadText Steps" "Texts Steps" "Checks Steps"',
    gridTemplateColumns: 'min(50%, 700px) 1fr',
    gridRowGap: '30px',
  },
}));

const HeadText = styled(Box, { name: 'SafetyWidget', slot: 'HeadText' })(({ theme }) => ({
  gridArea: 'HeadText',
  display: 'flex',
  gap: 12,
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    flexDirection: 'row',
    gap: 24,
    textAlign: 'start',
  },
}));

export interface SafetyWidgetProps {}
export const SafetyWidget = () => {
  return (
    <Root>
      <HeadText>
        <Image alt="shield" height={safety.height} src={safety.src} sx={{ flex: `0 0 ${safety.width}px` }} width={safety.width} />
        <Typography variant="h1">
          ВАША <TypographyAccent>БЕЗОПАСНОСТЬ</TypographyAccent> - наш приоритет
        </Typography>
      </HeadText>
      <Box sx={{ gridArea: 'Texts', textAlign: { xs: 'center', [MOBILE_BREAKPOINT_THRESHOLD]: 'start' } }}>
        <Typography component="p" variant="button">
          Мы стремимся помочь вам найти няню, которой можно доверять.
        </Typography>
        <Typography component="p" variant="button">
          Каждый бебиситтер проходит личное собеседование, обучение и проверку документов.
        </Typography>
      </Box>
      <Box
        sx={{
          gridArea: 'Steps',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: { xs: 'space-around', [MOBILE_BREAKPOINT_THRESHOLD]: 'end' },
        }}
      >
        <Steps sx={{ flex: '1 1 auto', maxWidth: '560px' }} />
      </Box>
      <Box
        sx={{
          gridArea: 'Checks',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: { [MOBILE_BREAKPOINT_THRESHOLD]: '30px' },
        }}
      >
        <Button color="pink" iconRight={<ArrowRightIcon />} variant="secondary">
          Как мы проверяем
        </Button>
      </Box>
    </Root>
  );
};

import React, { PropsWithChildren } from 'react';
import Head from 'next/head';
import { CurrentCityContextProvider } from '@cp/entities/location';
import { AppDeclinationProvider } from 'src/app/providers/declination';
import { ConfigProvider } from '#app/config';
import { CurrentRouteParamsProvider, RouterProvider } from '#app/router';
import { ClientWebRouteResolver } from '#app/routeResolver';
import { AppSuggestionsProvider } from '#app/form';
import { ModalProvider } from '#app/modal';
import { PageProps } from '#app/page';

export interface HomePageLayoutProps extends PageProps, PropsWithChildren {}

export const HomePageLayout = (props: HomePageLayoutProps) => {
  return (
    <ConfigProvider config={props.config}>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" name="viewport" />
      </Head>
      <RouterProvider>
        <ClientWebRouteResolver>
          <CurrentRouteParamsProvider routerContextValue={props.routerContextValue}>
            <CurrentCityContextProvider>
              <AppDeclinationProvider>
                <AppSuggestionsProvider>
                  <ModalProvider>{props.children}</ModalProvider>
                </AppSuggestionsProvider>
              </AppDeclinationProvider>
            </CurrentCityContextProvider>
          </CurrentRouteParamsProvider>
        </ClientWebRouteResolver>
      </RouterProvider>
    </ConfigProvider>
  );
};

import { CurrentCityContextProvider } from '@cp/entities/location';
import { AppDeclinationProvider } from 'src/app/providers/declination';
import React, { PropsWithChildren } from 'react';
import { RouterProvider } from '#app/router';
import { ClientWebRouteResolver } from '#app/routeResolver';
import { AppSuggestionsProvider } from '#app/form';
import { ModalProvider } from '#app/modal';
import { Header } from '#widgets/header';
import { Footer } from '#widgets/footer';

export const StaticPagesLayout = ({ children }: PropsWithChildren) => (
  <RouterProvider>
    <CurrentCityContextProvider>
      <ClientWebRouteResolver>
        <AppDeclinationProvider>
          <AppSuggestionsProvider>
            <ModalProvider>
              <Header />
              {children}
              <Footer />
            </ModalProvider>
          </AppSuggestionsProvider>
        </AppDeclinationProvider>
      </ClientWebRouteResolver>
    </CurrentCityContextProvider>
  </RouterProvider>
);

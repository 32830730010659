import React, { useEffect } from 'react';

export interface VideoReviewsProps {}

export const VideoReviews: React.FC<VideoReviewsProps> = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://app.getreview.io/tags/NEDTROAXJ2jIkWGb/sdk.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
  return <div className="getreview-widget" data-widget-id="kBnFviDBHChvBcUV"></div>;
};

import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const HeartFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="heartFilled" ref={ref as any} {...props}>
      <path
        d="M11.6147 7.0631L12.0554 7.88478L12.496 7.0631C12.6133 6.84447 12.7521 6.6137 12.9142 6.38489C13.5438 5.49622 14.6198 4.5 16.3001 4.5C17.5141 4.5 18.6164 4.997 19.4142 5.90232C20.1796 6.77082 20.6107 7.9455 20.6107 9.21798C20.6107 10.5755 20.0872 11.8672 18.8765 13.3072L18.8765 13.3072C17.7801 14.6111 16.1927 15.9658 14.301 17.5778L14.301 17.5778L14.2939 17.5838C13.5954 18.1791 12.8706 18.7968 12.0988 19.4721L12.0985 19.4723L12.0756 19.4924C12.0722 19.4953 12.0691 19.497 12.0662 19.4981C12.063 19.4993 12.0593 19.5 12.0554 19.5C12.0514 19.5 12.0477 19.4993 12.0446 19.4981C12.0416 19.497 12.0386 19.4954 12.0353 19.4925L12.0122 19.4723L12.012 19.4721C11.2379 18.7948 10.5111 18.1754 9.81081 17.5787L9.80986 17.5779L9.80985 17.5779C7.91802 15.9658 6.33062 14.6111 5.23428 13.3072L5.23427 13.3072C4.02353 11.8672 3.5 10.5755 3.5 9.21798C3.5 7.94549 3.93112 6.77084 4.69645 5.90238C5.49436 4.99704 6.5967 4.5 7.81067 4.5C9.49095 4.5 10.5669 5.49622 11.1966 6.38489L11.1966 6.3849C11.3587 6.61367 11.4975 6.84443 11.6147 7.0631Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

import React from 'react';
import { MOBILE_BREAKPOINT_THRESHOLD, styled } from '@cp/ui/theme';
import { Box, Image } from '@cp/ui/components';
import { Typography } from '@cp/ds/components/typography';
import { SxProps } from '@mui/system';
import step1 from './assets/step1.svg';
import step2 from './assets/step2.svg';
import step3 from './assets/step3.svg';
import adornment from './assets/adornment.svg';

const StepsRoot = styled(Box, { name: 'Steps' })(() => ({
  display: 'grid',
  gridTemplateAreas: '"a b" "c c"',
  gap: '12px',
}));

const StepRoot = styled(Box, { name: 'Step' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  gap: '8px',
  boxSizing: 'border-box',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  height: '100px',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    height: '140px',
  },
}));

const IMAGE_SIZE = 40;
const StepImage = styled(Image)(({ theme }) => ({
  '--dimension--size': `${IMAGE_SIZE}px`,
  width: 'var(--dimension--size)',
  height: 'var(--dimension--size)',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    '--dimension--size': '60px',
  },
}));

export const Steps = (props: { sx: SxProps }) => {
  return (
    <StepsRoot {...props}>
      <StepRoot sx={{ gridArea: 'a' }}>
        <StepImage alt="" height={40} src={step1.src} width={40} />
        <Typography variant="body bold">ЛИЧНОЕ СОБЕСЕДОВАНИЕ</Typography>
      </StepRoot>
      <StepRoot sx={{ gridArea: 'b' }}>
        <StepImage alt="" height={40} src={step2.src} width={40} />
        <Typography variant="body bold">ПРОВЕРКА ДОКУМЕНТОВ</Typography>
      </StepRoot>
      <StepRoot sx={{ gridArea: 'c', background: `url(${adornment.src}) bottom right no-repeat`, flexDirection: 'row', gap: '12px' }}>
        <StepImage alt="" height={40} src={step3.src} width={40} />
        <Typography variant="body bold">ОБУЧЕНИЕ и ТЕСТИРОВАНИЕ</Typography>
      </StepRoot>
    </StepsRoot>
  );
};

import React, { ReactNode, createElement, useCallback, useRef } from 'react';
import SwiperInstance from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SwiperOptions } from 'swiper';
import { Navigation } from 'swiper/modules';
import { Box, BoxProps } from '@cp/ui/components';
import 'swiper/css';
import { SxProps, styled } from '@cp/ui/theme';

const Root = styled(Box, { name: 'Swiper', slot: 'root' })({
  position: 'relative',
});

const Wrapper = styled(Box, { name: 'Swiper', slot: 'wrapper' })({});

export interface SliderProps extends BoxProps {
  wrapperSx?: SxProps;
  config?: SwiperOptions;
  onSwiper?: (swiper: SwiperInstance) => void;
  onSlideChange?: (swiper: SwiperInstance) => void;
  after?: ReactNode;
  before?: ReactNode;
}

export const SwiperSlider = ({ config, onSwiper: _onSwiper, onSlideChange, before, after, ...props }: SliderProps) => {
  const children = props.children ? (Array.isArray(props.children) ? props.children : [props.children]) : [];
  const swiperRef = useRef<SwiperInstance>(null);

  const onSwiper = useCallback(
    (swiper: SwiperInstance) => {
      swiperRef.current = swiper;
      _onSwiper?.(swiper);
    },
    [_onSwiper],
  );

  return (
    <Root {...props}>
      {before}
      <Wrapper>
        {createElement(
          Swiper,
          {
            modules: [Navigation],
            ...config,
            onSwiper,
            onSlideChange,
          },
          children.map((slide, key) => createElement(SwiperSlide, { key }, slide)),
        )}
      </Wrapper>
      {after}
    </Root>
  );
};

import React from 'react';
import { coreRouterApi, useClientWebRouteParams } from '@cp/entities/clientWebRoutes';
import { resolveTemplateString } from '@common/utils/template';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { ClientWebRouteMetaViewModel } from '@common/model/meta/clientWebRouteMetaViewModel';
import {
  ClientWebPageCommonTemplateParameters,
  ClientWebPageTemplateParametersDictionary,
} from '@common/model/meta/clientWebPageTemplateParameters';
import { useCommonTemplateParameters } from './useCommonTemplateParameters';

export interface MetaTagRenderConfig {
  tagName: string;
  tagContent: string;
}

export type MetaTagName = keyof Pick<ClientWebRouteMetaViewModel, 'description' | 'h1' | 'keywords' | 'title'>;

const HEAD_META_TAG_NAMES: MetaTagName[] = ['title', 'description', 'keywords'];

export const usePageMetaTags = <P extends ClientWebPage>({
  dictionary: pageSpecDict,
  tagNames = HEAD_META_TAG_NAMES,
}: {
  dictionary?: Omit<ClientWebPageTemplateParametersDictionary[P], ClientWebPageCommonTemplateParameters>;
  tagNames?: MetaTagName[];
} = {}): MetaTagRenderConfig[] => {
  const { routeId } = useClientWebRouteParams();
  const { data: routeMeta } = coreRouterApi.useGetClientWebRouteMetaQuery({ routeId: Number(routeId) }, { skip: !routeId });

  const commonParameters = useCommonTemplateParameters();

  return React.useMemo<MetaTagRenderConfig[]>(() => {
    if (!routeMeta) {
      return [];
    }

    const dictionary = { ...commonParameters, ...pageSpecDict };

    return tagNames
      .filter((tn) => Boolean(routeMeta[tn]))
      .map((tn) => {
        return {
          tagName: tn,
          tagContent: resolveTemplateString(String(routeMeta[tn]), dictionary),
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonParameters, pageSpecDict, routeMeta, ...tagNames]);
};

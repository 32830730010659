import { Box } from '@cp/ui/components';
import { SxProps, styled } from '@cp/ui/theme';
import React, { FC, ReactNode } from 'react';
import { AdvantageBody } from './advantageBody';

const Root = styled(Box, { name: 'AdvantageWidget' })({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  textAlign: 'center',
});

export interface AdvantageWidgetProps {
  header?: ReactNode;
  sx?: SxProps;
}
export const AdvantageWidget: FC<AdvantageWidgetProps> = ({ header, ...props }) => {
  return (
    <Root {...props}>
      {header}
      <AdvantageBody />
    </Root>
  );
};

import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const InfoSolidIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="infoSolid" ref={ref as any} {...props}>
      <path
        clipRule="evenodd"
        d="M21 12C21 16.9705 16.9705 21 12 21C7.02943 21 3 16.9705 3 12C3 7.02943 7.02943 3 12 3C16.9705 3 21 7.02943 21 12ZM12 17.175C12.3728 17.175 12.675 16.8728 12.675 16.5V11.1C12.675 10.7272 12.3728 10.425 12 10.425C11.6272 10.425 11.325 10.7272 11.325 11.1V16.5C11.325 16.8728 11.6272 17.175 12 17.175ZM12 7.5C12.4971 7.5 12.9 7.90295 12.9 8.4C12.9 8.89705 12.4971 9.3 12 9.3C11.5029 9.3 11.1 8.89705 11.1 8.4C11.1 7.90295 11.5029 7.5 12 7.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
});

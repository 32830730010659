import React from 'react';
import { styled } from '@cp/ui/theme';
import { Box } from '@cp/ui/components';
import { Typography } from '@cp/ds/components/typography';
import { HeartFilledIcon, InfoSolidIcon } from '@cp/ds/icons';

const Item = styled(Box)({
  display: 'flex',
  gap: '6px',
});

export interface ReviewContentProps {
  subject: string;
  description: string;
}
export const ReviewContent: React.FC<ReviewContentProps> = ({ subject, description, ...props }) => {
  return (
    <Box {...props}>
      <Item>
        <HeartFilledIcon />
        <Typography>{subject}</Typography>
      </Item>
      <Item>
        <InfoSolidIcon />
        <Typography>{description}</Typography>
      </Item>
    </Box>
  );
};

import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@cp/ui/components';
import { styled } from '@cp/ui/theme';
import { createCssVar, createGetCssVar, getCssToken, getCssVar } from '@cp/ui/utils';
import { Typography } from '@cp/ds/components/typography';
import { RatingBar } from '@cp/ds/components/ratingBar';

const name = 'ReviewCard';
const token = createCssVar(name);
const getToken = createGetCssVar(name);

const Root = styled(Box, { name, slot: 'root' })(({ theme }) => ({
  [token('divider')]: theme.palette.divider,
  [token('header-text')]: getCssToken('color', 'accent', theme.palette.brand.main),
  border: '1px solid',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: getCssToken('dimension', 'gap', '8px'),
  borderColor: getCssToken('color', 'accent', theme.palette.brand.main),
  color: getCssToken('color', 'text'),
  padding: getCssToken('dimension', 'padding', '20px'),
  boxShadow: '0px 2.17px 3.25px 0px rgba(0, 0, 0, 0.1)',
}));
const Header = styled('header', { name: 'ReviewCard', slot: 'header' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: getToken('header-text'),
});
const Body = styled(Box, { name: 'ReviewCard', slot: 'body' })({});
const Footer = styled('footer', { name: 'ReviewCard', slot: 'footer' })({
  borderTop: '1px solid',
  borderColor: getCssVar(name, 'divider'),
  paddingTop: '20px',
});

export interface ReviewCardProps extends BoxProps {
  rating: number;
  header?: ReactNode;
  footer?: ReactNode;
}
export const ReviewCard: React.FC<ReviewCardProps> = ({ header, rating, footer, children, ...props }) => {
  return (
    <Root {...props}>
      <Header>
        <Typography variant="h3">{header}</Typography>
        <RatingBar iconSize={1.5} value={rating} />
      </Header>
      <Body>{children}</Body>
      <Footer>{footer}</Footer>
    </Root>
  );
};

import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const StarIcon = React.forwardRef<SVGSVGElement, Omit<SvgIconProps, 'cacheName'>>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="star" ref={ref as any} {...props}>
      <path
        d="M22.6441 10.1431L18.1795 14.4876L19.2332 20.6224C19.3088 21.0641 19.1258 21.5111 18.763 21.7749C18.5577 21.9239 18.3134 21.9999 18.0691 21.9999C17.8815 21.9999 17.6927 21.9551 17.5198 21.8645L12.0002 18.9681L6.48061 21.8645C6.08294 22.0735 5.60149 22.0382 5.23745 21.7761C4.87459 21.5123 4.69227 21.0659 4.76838 20.6236L5.82274 14.4887L1.35691 10.1437C1.03417 9.83094 0.919707 9.36274 1.05718 8.93636C1.19642 8.50998 1.56519 8.19961 2.01006 8.13483L8.18104 7.23908L10.9423 1.65724C11.14 1.25442 11.5506 1 11.9996 1C12.4498 1 12.8587 1.25442 13.0575 1.65665L15.8188 7.23849L21.991 8.13424C22.4347 8.19844 22.8046 8.50939 22.9415 8.93577C23.0819 9.36215 22.9645 9.83035 22.6441 10.1431Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

import React from 'react';
import { styled } from '@cp/ui/theme';
import { Box, BoxProps } from '@cp/ui/components';
import { Typography } from '@cp/ds/components/typography';

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export interface ReviewPriceProps extends BoxProps {
  value: number;
}
export const ReviewPrice: React.FC<ReviewPriceProps> = ({ value, ...props }) => {
  return (
    <Root {...props}>
      <Typography variant="body bold">Стоимость заказа</Typography>
      <Typography variant="h3">{value}₽</Typography>
    </Root>
  );
};

import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, Image } from '@cp/ui/components';
import { MOBILE_BREAKPOINT_THRESHOLD, styled } from '@cp/ui/theme';
import { Typography } from '@cp/ds/components/typography';
import { SectionHeader } from '#shared/ui/section';
import step11 from './assets/1.png';
import step21 from './assets/2.png';
import step31 from './assets/3.png';

const Root = styled(Box, { name: 'HowToUse' })(({ theme }) => ({
  '--header-gap': '20px',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    '--header-gap': '60px',
  },
}));
const Steps = styled('ol')(({ theme }) => ({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginBlockStart: 0,
  marginBlockEnd: 0,
  paddingInlineStart: 0,
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    flexDirection: 'row',
    alignItems: 'start',
  },
}));

const Step = styled('li')(({ theme }) => ({
  '--step-number-size': '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
    '--step-number-size': '52px',
    flexDirection: 'column-reverse',
  },
}));
const StepNumber = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'var(--step-number-size)',
  height: 'var(--step-number-size)',
  borderRadius: '100%',
  marginTop: '12px',
  backgroundColor: theme.palette.brand.main,
  color: theme.palette.brand.contrastText,
}));
const StepText = styled(Box, { name: 'HowToUse' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  gap: '8px',
  '--color--text-secondary': theme.palette.text.secondary,
}));

const IMAGE_WIDTH = { xs: 220, [MOBILE_BREAKPOINT_THRESHOLD]: 300 };
const IMAGE_HEIGHT = { xs: 448, [MOBILE_BREAKPOINT_THRESHOLD]: 610 };

export interface HowToUseProps extends BoxProps {
  header?: ReactNode;
}
export const HowToUse: FC<HowToUseProps> = ({ header, ...props }) => {
  return (
    <Root {...props}>
      {header && <SectionHeader>{header}</SectionHeader>}
      <Steps>
        <Step>
          <StepText>
            <StepNumber>
              <Typography variant="h3">1</Typography>
            </StepNumber>
            <Typography variant="h3">Выбирайте обученных нянь</Typography>
            <Typography sx={{ color: 'var(--color--text-secondary)' }}>
              Ищите в анкетах отметку “школа Babytime” - этих специалистов мы обучили и проверили
            </Typography>
          </StepText>
          <Image alt="" height={IMAGE_HEIGHT.xs} loading="lazy" src={step11.src} width={IMAGE_WIDTH.xs} />
        </Step>
        <Step>
          <StepText>
            <StepNumber>
              <Typography variant="h3">2</Typography>
            </StepNumber>
            <Typography variant="h3">Используйте фильтр по времени</Typography>
            <Typography sx={{ color: 'var(--color--text-secondary)' }}>
              Исключите из поиска специалистов, которые заняты, на выходном или в отпуске
            </Typography>
          </StepText>
          <Image alt="" height={IMAGE_HEIGHT.xs} loading="lazy" src={step21.src} width={IMAGE_WIDTH.xs} />
        </Step>
        <Step>
          <StepText>
            <StepNumber>
              <Typography variant="h3">3</Typography>
            </StepNumber>
            <Typography variant="h3">Выбирайте специалистов рядом с вами</Typography>
            <Typography sx={{ color: 'var(--color--text-secondary)' }}>
              Устанавливайте фильтры, читайте отзывы и приглашайте подходящего специалиста
            </Typography>
          </StepText>
          <Image alt="" height={IMAGE_HEIGHT.xs} loading="lazy" src={step31.src} width={IMAGE_WIDTH.xs} />
        </Step>
      </Steps>
    </Root>
  );
};

import { EmploymentAgencySchema } from './model';

export const getEmploymentAgencySchema = (useAddress = true, useOpeningHours = true, useLocation = true): EmploymentAgencySchema => {
  const schema: EmploymentAgencySchema = {
    '@type': 'EmploymentAgency',
    name: 'YouHelp',
    legalName: 'ООО «ЮХЕЛП»',
    currenciesAccepted: 'RUB',
    paymentAccepted: 'Credit Card',
    areaServed: 'Россия',
    brand: {
      '@type': 'Brand',
      //aggregateRating: "",
      logo: 'https://youhelp.ru/img/logo.png',
      name: 'YouHelp',
      // review:...
      slogan: 'Найдите проверенных специалистов',
    },
    email: 'admin@youhelp.ru',
    ethicsPolicy: 'https://youhelp.ru/privacy-policy/',
    //publishingPrinciples: "",
    logo: 'https://youhelp.ru/img/logo.png',
    image: 'https://youhelp.ru/img/logo.png',
    slogan: 'Найдите проверенных специалистов',
    telephone: '8-800-301-76-15',
    taxID: '5903143979',
    description:
      'YouHelp — крупнейший в мире сервис по уходу за вашими близкими. Мы соединяем семьи с заботливыми специалистами, чтобы помочь Вам в уходе за любимыми.',
    url: 'https://youhelp.ru',
    priceRange: '390 ₽ - 3900 ₽',
  };

  if (useAddress) {
    schema.address = {
      '@type': 'PostalAddress',
      addressCountry: 'Россия',
      addressLocality: 'Пермь',
      addressRegion: 'Пермский край',
      postalCode: '614026',
      streetAddress: 'ул Пушкарская, Д. 140, офис 407А',
      //postOfficeBoxNumber: "123",
    };
  }

  if (useLocation) {
    const sdLocation: {
      '@type': 'Place';
      latitude: string;
      longitude: string;
    } = {
      '@type': 'Place',
      latitude: '58.12658',
      longitude: '56.389712',
    };
    schema.location = sdLocation;
    schema.contactPoint = {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      areaServed: sdLocation,
    };
  }

  if (useOpeningHours) {
    schema.openingHours = 'Mo-Su 9:00-18:00';
  }

  return schema;
};

import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import { StarIcon } from '../../icons/star';
import { StyledSpan } from '../styled/styledSpan';
import { RATING_BAR_ICON_SIZE_DEFAULT, RATING_BAR_ITEM_SPACING, RATING_BAR_LENGTH } from './const';
import { RatingBarProps } from './model';
export type { RatingBarProps } from './model';

export const RatingBar: React.FC<RatingBarProps> = ({ value, iconSize = RATING_BAR_ICON_SIZE_DEFAULT, sx }) => {
  const theme = useTheme();
  const fontSize = `${iconSize}rem`;

  return (
    <StyledSpan sx={{ display: 'inline-flex', alignItems: 'center', '& > *:not(:first-of-type)': { ml: RATING_BAR_ITEM_SPACING }, ...sx }}>
      {Array.from(new Array(RATING_BAR_LENGTH), (_, index) => {
        if (index < value && index + 1 > value) {
          const remain = value - index;

          return (
            <StyledSpan key={index} sx={{ position: 'relative', width: fontSize, height: fontSize }}>
              <StarIcon sx={{ fontSize, color: theme.palette['gray middle'], position: 'absolute', top: 0, left: 0 }} />
              {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
              <StyledSpan sx={{ position: 'absolute', zIndex: 1, width: `${100 * remain}%`, height: fontSize, overflow: 'hidden' }}>
                <StarIcon sx={{ fontSize, color: theme.palette['brand pink'], position: 'absolute', top: 0, left: 0 }} />
              </StyledSpan>
            </StyledSpan>
          );
        }

        return <StarIcon key={index} sx={{ fontSize, color: theme.palette[index < value ? 'brand pink' : 'gray middle'] }} />;
      })}
    </StyledSpan>
  );
};

import React, { PropsWithChildren } from 'react';
import Head from 'next/head';
import { CurrentCityContextProvider } from '@cp/entities/location';
import { AppDeclinationProvider } from 'src/app/providers/declination';
import { HeaderFeature } from 'src/widgets/header';
import { ConfigProvider } from '#app/config';
import { CurrentRouteParamsProvider, RouterProvider } from '#app/router';
import { ClientWebRouteResolver } from '#app/routeResolver';
import { AppSuggestionsProvider } from '#app/form';
import { ModalProvider } from '#app/modal';
import { Header } from '#widgets/header';
import { PageProps } from '#app/page';
import { BottomMenuWidget } from '#widgets/bottomMenu';

export interface ProfilePagesLayoutProps extends PageProps, PropsWithChildren {}

export const ProfilePagesLayout = (props: ProfilePagesLayoutProps) => {
  return (
    <ConfigProvider config={props.config}>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" name="viewport" />
      </Head>
      <RouterProvider>
        <ClientWebRouteResolver>
          <CurrentRouteParamsProvider routerContextValue={props.routerContextValue}>
            <CurrentCityContextProvider>
              <AppDeclinationProvider>
                <AppSuggestionsProvider>
                  <ModalProvider>
                    <Header
                      desktopFeatures={{
                        [HeaderFeature.CitySelection]: false,
                        [HeaderFeature.NavigateToCatalog]: true,
                        [HeaderFeature.ClientMenu]: true,
                      }}
                      sticky
                    />
                    {props.children}
                    <BottomMenuWidget />
                  </ModalProvider>
                </AppSuggestionsProvider>
              </AppDeclinationProvider>
            </CurrentCityContextProvider>
          </CurrentRouteParamsProvider>
        </ClientWebRouteResolver>
      </RouterProvider>
    </ConfigProvider>
  );
};

import React from 'react';
import { GetServerSideProps } from 'next';
import { Home } from '#pages/home';
import { getCommonProps } from '#app/ssr';
import { PageProps } from '#app/page';
import { HomePageLayout } from '#app/layout';

export default function HomePage(props: PageProps) {
  return (
    <HomePageLayout {...props}>
      <Home />
    </HomePageLayout>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ req, res, query }) => {
  const props = await getCommonProps({ req, res, query });
  return { props };
};
